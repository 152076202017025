@import "../globals/variables.css";
@import "../globals/global.module.css";
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 0;
}

.filterContainer{
    padding: 1rem;
    border-bottom: 3px solid #89C5CA;
    margin-bottom: 0.5rem;
    /* display: flex;
    align-items: center;
    justify-content: flex-start; */

}
.tableHead th {
    font-weight: bold !important;
    color: white !important;
    background: var(--background--table) 0% 0% no-repeat padding-box !important;
    text-align: center !important;
}
.button {
    composes: primaryButton from "../globals/global.module.css";
    margin-right: 5px;
    /* background: var(--primary-color) 0% 0% no-repeat padding-box !important; */
}
.secondaryButton {
    composes: secondaryButton from "../globals/global.module.css";
    margin-right: 5px;
    /* background: var(--primary-color) 0% 0% no-repeat padding-box !important; */
}

.outlinedButton {
    composes: primaryOutlinedButton from "../globals/global.module.css";
}
.redoutlinedButton {
    composes: redOutlinedButton from "../globals/global.module.css";
}