.nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #a0e1a9;
    /* background: transparent linear-gradient(270deg, #13d470 0%, #147c46 100%) 0% 0% no-repeat padding-box; */
    /* background: transparent linear-gradient(90deg, #5fc4cb 0%, #78a4a7 100%) 0% 0% no-repeat
        padding-box; */
    box-shadow: 0px 2px 5px #00000029;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.nav li {
    list-style: none;
    padding: 0 20px;
    position: relative;
}

.nav li a {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    transition: 0.3s ease-in-out;
}
.nav li a:hover,
.nav li a.active {
    color: #e9e9e9;
    /* font-weight: bold; */
    /* transition: font-weight 0.3s ease, opacity 0.3s ease, transform 0.3s ease; */
}

.nav li a:hover::after,
.nav li a.active::after {
    content: "";
    width: 60%;
    height: 2px;
    background: #0b694d;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
}
.logo {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}
.center {
    flex: 2;
}
.menu {
    flex: 3;
    display: flex;
    justify-content: flex-start;
}

#mobile {
    display: none;
}

#mobile i {
    color: #fff;
    align-items: center;
}
@media screen and (max-width: 769px) {
    /* .nav {
        position: unset;
    } */
    .navMenu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 90px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background: #2a3239;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    .navMenu.active {
        right: 0px;
    }

    .nav li {
        margin-bottom: 25px;
    }
    .nav li a:hover,
    .nav li a.active {
        color: #0b694d;
        font-weight: bold;
        transition: font-weight 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
    }
    .nav li a:hover::after,
    .nav li a.active::after {
        content: "";
        width: 20%;
        height: 2px;
        background: #0b694d;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
    }
    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }
}
