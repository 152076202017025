.navbar {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: var(--darkgrey);
    flex: 1;
}

.navbar img {
    width: 80px;
}

.nav-menu {
    display: flex;
}

.nav-item {
    padding: 1rem;
    cursor: pointer;
}

.hamburger {
    display: none;
}

@media screen and (max-width: 940px) {
    .navbar {
        justify-content: flex-end;
    }
    .nav-menu {
        position: fixed;
        right: -110%;
        top: -5%;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        width: 101%;
        height: -webkit-fill-available;
        text-align: center;
        transition: 0.4s;
        z-index: 1000;
    }

    .nav-menu.active {
        right: 0;
    }

    .nav-item {
        margin: 1.5rem;
        font-size: 1.8rem;
        justify-content: center;
    }

    .hamburger {
        display: flex;
    }
    .hamburger svg{
        color: rgb(0, 0, 0) !important;
        z-index: 1000;
    }
}
