@import "../../globals/global.module.css";
.container {
    max-width: unset;
}
.headerModal {
    border-bottom: 6px var(--primary-color) !important;
    color: #1d2129 !important;
    background-color: white !important;
}
.icon {
    color:var(--primary-color);
}
.button {
    composes: primaryButton from "../../globals/global.module.css";
    margin-right: 5px;
    /* background: var(--primary-color) 0% 0% no-repeat padding-box !important; */
}
.outlinedButton {
    composes: primaryOutlinedButton from "../../globals/global.module.css";
}
