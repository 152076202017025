.root{
    width: 100%;
    /* height: 100%; */
    padding: 0;
    margin: 0;
    /* font-family: Arial, Helvetica, sans-serif; */
}
@font-face {
    font-family: 'Lobster';
    src: url('./assets/fonts/Lobster-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }