.imageContainer {
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.imageContainer img {
    max-width: 100%;
    height: 20rem;
    object-fit: contain;
    padding: 1rem;
}
.arrowCustom::before, .arrowCustom::after{
    color: #979898 !important ;
    font-size: 40px !important;
    display: block !important;
    z-index: 99;
}