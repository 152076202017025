.containerClient {
    width: 100%;
    /* height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    background-color: var(--background--common);
}
.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background--common);
}
.body {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* height: calc(100vh - 6rem - 15rem); */
    width: 100%;
}
