.customSlider {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
.customSlider div {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}
.customSlider .slick-slide {
    width: 100% !important;
    height: 100% !important;
}
.customSlider img {
    border-radius: inherit;
    cursor: pointer;
}